import React from "react";
import { Link } from "gatsby";

function IOSTermsAndConditions() {
  return (
    <div style={{ maxWidth: 838, height: "100%", margin: "0 auto" }}>
      <div
        style={{
          margin: "0px auto",
          fontFamily: "Roboto, Arial",
          padding: "50px 10px 100px",
        }}
      >
        <h1 style={{ fontFamily: "Arial", fontSize: 26, color: "black", fontWeight: "bold" }}>
          END USER LICENSE AGREEMENT
        </h1>
        <p style={{ fontFamily: "Arial", fontSize: 14, color: "#595959", fontWeight: "bold" }}>
          Last updated August 27, 2021
        </p>
        <p className="legal-body">
          Wandry is licensed to You (End-User) by Guest Experience Maximization
          LLC, located at 5728 Major Blvd. Suite 200, 5728 Major Blvd. Suite
          200, Orlando, Florida 32819, United States (hereinafter: Licensor),
          for use only under the terms of this License Agreement.
        </p>
        <p className="legal-body">
          By downloading the Application from the Apple AppStore, and any update
          thereto (as permitted by this License Agreement), You indicate that
          You agree to be bound by all of the terms and conditions of this
          License Agreement, and that You accept this License Agreement.
        </p>
        <p className="legal-body">
          The parties of this License Agreement acknowledge that Apple is not a
          Party to this License Agreement and is not bound by any provisions or
          obligations with regard to the Application, such as warranty,
          liability, maintenance and support thereof. Guest Experience
          Maximization LLC, not Apple, is solely responsible for the licensed
          Application and the content thereof.
        </p>
        <p className="legal-body">
          This License Agreement may not provide for usage rules for the
          Application that are in conflict with the latest{" "}
          <a href="https://www.apple.com/legal/internet-services/itunes/us/terms.html">
            App Store Terms of Service
          </a>
          . Guest Experience Maximization LLC acknowledges that it had the
          opportunity to review said terms and this License Agreement is not
          conflicting with them.
        </p>
        <p className="legal-body">
          All rights not expressly granted to You are reserved.
        </p>
        <h2 className="legal-title">1. THE APPLICATION</h2>
        <p className="legal-body">
          Wandry (hereinafter: Application) is a piece of software created to
          Provide travelers with destination data in relation to safety,
          itineraries, and local procedures. - and customized for Apple mobile
          devices. It is used to Travel and Vacation planning.
        </p>
        <p className="legal-body">
          The Application is not tailored to comply with industry-specific
          regulations (Health Insurance Portability and Accountability Act
          (HIPAA), Federal Information Security Management Act (FISMA), etc.),
          so if your interactions would be subjected to such laws, you may not
          use this Application. You may not use the Application in a way that
          would violate the Gramm-Leach-Bliley Act (GLBA).
        </p>
        <h2 className="legal-title">2. SCOPE OF LICENSE</h2>
        <p className="legal-body">
          2.1 You may not share or make the Application available to third
          parties (unless to the degree allowed by the Apple Terms and
          Conditions, and with Guest Experience Maximization LLC's prior written
          consent), sell, rent, lend, lease or otherwise redistribute the
          Application.
        </p>
        <p className="legal-body">
          2.2 You may not reverse engineer, translate, disassemble, integrate,
          decompile, integrate, remove, modify, combine, create derivative works
          or updates of, adapt, or attempt to derive the source code of the
          Application, or any part thereof (except with Guest Experience
          Maximization LLC's prior written consent).
        </p>
        <p className="legal-body">
          2.3 You may not copy (excluding when expressly authorized by this
          license and the Usage Rules) or alter the Application or portions
          thereof. You may create and store copies only on devices that You own
          or control for backup keeping under the terms of this license, the App
          Store Terms of Service, and any other terms and conditions that apply
          to the device or software used. You may not remove any intellectual
          property notices. You acknowledge that no unauthorized third parties
          may gain access to these copies at any time.
        </p>
        <p className="legal-body">
          2.4 Violations of the obligations mentioned above, as well as the
          attempt of such infringement, may be subject to prosecution and
          damages.
        </p>
        <p className="legal-body">
          2.5 Licensor reserves the right to modify the terms and conditions of
          licensing.
        </p>
        <p className="legal-body">
          2.6 Nothing in this license should be interpreted to restrict
          third-party terms. When using the Application, You must ensure that
          You comply with applicable third-party terms and conditions.
        </p>
        <h2 className="legal-title">3. TECHNICAL REQUIREMENTS</h2>
        <p className="legal-body">
          3.1 Licensor attempts to keep the Application updated so that it
          complies with modified/new versions of the firmware and new hardware.
          You are not granted rights to claim such an update.
        </p>
        <p className="legal-body">
          3.2 You acknowledge that it is Your responsibility to confirm and
          determine that the app end-user device on which You intend to use the
          Application satisfies the technical specifications mentioned above.
        </p>
        <p className="legal-body">
          3.3 Licensor reserves the right to modify the technical specifications
          as it sees appropriate at any time.
        </p>
        <h2 className="legal-title">4. MAINTENANCE AND SUPPORT</h2>
        <p className="legal-body">
          4.1 The Licensor is solely responsible for providing any maintenance
          and support services for this licensed Application. You can reach the
          Licensor at the email address listed in the App Store Overview for
          this licensed Application.
        </p>
        <p className="legal-body">
          4.2 Guest Experience Maximization LLC and the End-User acknowledge
          that Apple has no obligation whatsoever to furnish any maintenance and
          support services with respect to the licensed Application.
        </p>
        <h2 className="legal-title">5. USE OF DATA</h2>
        <p className="legal-body">
          You acknowledge that Licensor will be able to access and adjust Your
          downloaded licensed Application content and Your personal information,
          and that Licensor's use of such material and information is subject to
          Your legal agreements with Licensor and <Link to="/privacy-policy">Licensor's privacy policy</Link>.
        </p>
        <h2 className="legal-title">6. USER GENERATED CONTRIBUTIONS</h2>
        <p className="legal-body">
          The Application may invite you to chat, contribute to, or participate
          in blogs, message boards, online forums, and other functionality, and
          may provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or in the Application, including but not limited to
          text, writings, video, audio, photographs, graphics, comments,
          suggestions, or personal information or other material (collectively,
          "Contributions"). Contributions may be viewable by other users of the
          Application and through third-party websites or applications. As such,
          any Contributions you transmit may be treated as non-confidential and
          non-proprietary. When you create or make available any Contributions,
          you thereby represent and warrant that:
        </p>
        <div className="legal-list-items">
          <p className="legal-body">
            1. The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </p>
          <p className="legal-body">
            2. You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Application, and other users of the Application to use your
            Contributions in any manner contemplated by the Application and
            these Terms of Use.
          </p>
          <p className="legal-body">
            3. You have the written consent, release, and/or permission of each
            and every identifiable individual person in your Contributions to
            use the name or likeness or each and every such identifiable
            individual person to enable inclusion and use of your Contributions
            in any manner contemplated by the Application and these Terms of
            Use.
          </p>
          <p className="legal-body">
            4. Your Contributions are not false, inaccurate, or misleading.
          </p>
          <p className="legal-body">
            5. Your Contributions are not unsolicited or unauthorized
            advertising, promotional materials, pyramid schemes, chain letters,
            spam, mass mailings, or other forms of solicitation.
          </p>
          <p className="legal-body">
            6. Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </p>
          <p className="legal-body">
            7. Your Contributions do not ridicule, mock, disparage, intimidate,
            or abuse anyone.
          </p>
          <p className="legal-body">
            8. Your Contributions are not used to harass or threaten (in the
            legal sense of those terms) any other person and to promote violence
            against a specific person or class of people.
          </p>
          <p className="legal-body">
            9. Your Contributions do not violate any applicable law, regulation,
            or rule.
          </p>
          <p className="legal-body">
            10. Your Contributions do not violate the privacy or publicity
            rights of any third party.
          </p>
          <p className="legal-body">
            11. Your Contributions do not contain any material that solicits
            personal information from anyone under the age of 18 or exploits
            people under the age of 18 in a sexual or violent manner.
          </p>
          <p className="legal-body">
            12. Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-being of minors.
          </p>
          <p className="legal-body">
            13. Your Contributions do not include any offensive comments that
            are connected to race, national origin, gender, sexual preference,
            or physical handicap.
          </p>
          <p className="legal-body">
            14. Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Terms of Use, or any
            applicable law or regulation.
          </p>
        </div>
        <p className="legal-body">
          Any use of the Application in violation of the foregoing violates
          these Terms of Use and may result in, among other things, termination
          or suspension of your rights to use the Application.
        </p>
        <h2 className="legal-title">7. CONTRIBUTION LICENSE</h2>
        <p className="legal-body">
          By posting your Contributions to any part of the Application or making
          Contributions accessible to the Application by linking your account
          from the Application to any of your social networking accounts, you
          automatically grant, and you represent and warrant that you have the
          right to grant, to us an unrestricted, unlimited, irrevocable,
          perpetual, non-exclusive, transferable, royalty-free, fully-paid,
          worldwide right, and license to host, use copy, reproduce, disclose,
          sell, resell, publish, broad cast, retitle, archive, store, cache,
          publicly display, reformat, translate, transmit, excerpt (in whole or
          in part), and distribute such Contributions (including, without
          limitation, your image and voice) for any purpose, commercial
          advertising, or otherwise, and to prepare derivative works of, or
          incorporate in other works, such as Contributions, and grant and
          authorize sublicenses of the foregoing. The use and distribution may
          occur in any media formats and through any media channels.
        </p>
        <p className="legal-body">
          This license will apply to any form, media, or technology now known or
          hereafter developed, and includes our use of your name, company name,
          and franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you
          provide. You waive all moral rights in your Contributions, and you
          warrant that moral rights have not otherwise been asserted in your
          Contributions.
        </p>
        <p className="legal-body">
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in any area in the Application.
          You are solely responsible for your Contributions to the Application
          and you expressly agree to exonerate us from any and all
          responsibility and to refrain from any legal action against us
          regarding your Contributions.
        </p>
        <p className="legal-body">
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to re-categorize
          any Contributions to place them in more appropriate locations in the
          Application; and (3) to pre-screen or delete any Contributions at any
          time and for any reason, without notice. We have no obligation to
          monitor your Contributions.
        </p>
        <h2 className="legal-title">8. LIABILITY</h2>
        <p className="legal-body">
          8.1 Licensor takes no accountability or responsibility for any damages
          caused due to a breach of duties according to Section 2 of this
          Agreement. To avoid data loss, You are required to make use of backup
          functions of the Application to the extent allowed by applicable
          third-party terms and conditions of use. You are aware that in case of
          alterations or manipulations of the Application, You will not have
          access to licensed Application.
        </p>
        <h2 className="legal-title">9. WARRANTY</h2>
        <p className="legal-body">
          9.1 Licensor warrants that the Application is free of spyware, trojan
          horses, viruses, or any other malware at the time of Your download.
          Licensor warrants that the Application works as described in the user
          documentation.
        </p>
        <p className="legal-body">
          9.2 No warranty is provided for the Application that is not executable
          on the device, that has been unauthorizedly modified, handled
          inappropriately or culpably, combined or installed with inappropriate
          hardware or software, used with inappropriate accessories, regardless
          if by Yourself or by third parties, or if there are any other reasons
          outside of Guest Experience Maximization LLC's sphere of influence
          that affect the executability of the Application.
        </p>
        <p className="legal-body">
          9.3 You are required to inspect the Application immediately after
          installing it and notify Guest Experience Maximization LLC about
          issues discovered without delay by e-mail provided in <a href="#product-claims">Product Claims</a>.
          The defect report will be taken into consideration and further
          investigated if it has been mailed within a period of seven (7) days
          after discovery.
        </p>
        <p className="legal-body">
          9.4 If we confirm that the Application is defective, Guest Experience
          Maximization LLC reserves a choice to remedy the situation either by
          means of solving the defect or substitute delivery.
        </p>
        <p className="legal-body">
          9.5 In the event of any failure of the Application to conform to any
          applicable warranty, You may notify the App-Store-Operator, and Your
          Application purchase price will be refunded to You. To the maximum
          extent permitted by applicable law, the App-Store-Operator will have
          no other warranty obligation whatsoever with respect to the App, and
          any other losses, claims, damages, liabilities, expenses and costs
          attributable to any negligence to adhere to any warranty.
        </p>
        <p className="legal-body">
          9.6 If the user is an entrepreneur, any claim based on faults expires
          after a statutory period of limitation amounting to twelve (12) months
          after the Application was made available to the user. The statutory
          periods of limitation given by law apply for users who are consumers.
        </p>
        <h2 className="legal-title" id="product-claims">10. PRODUCT CLAIMS</h2>
        <div className="legal-list-items">
          <p className="legal-body">
            Guest Experience Maximization LLC and the End-User acknowledge that
            Guest Experience Maximization LLC, and not Apple, is responsible for
            addressing any claims of the End-User or any third party relating to
            the licensed Application or the End-User’s possession and/or use of
            that licensed Application, including, but not limited to:
          </p>
          <p className="legal-body">(i) product liability claims;</p>
          <p className="legal-body">
            (ii) any claim that the licensed Application fails to conform to any
            applicable legal or regulatory requirement; and
          </p>
          <p className="legal-body">
            (iii) claims arising under consumer protection, privacy, or similar
            legislation, including in connection with Your Licensed
            Application’s use of the HealthKit and HomeKit.
          </p>
        </div>
        <h2 className="legal-title">11. LEGAL COMPLIANCE</h2>
        <p className="legal-body">
          You represent and warrant that You are not located in a country that
          is subject to a U.S. Government embargo, or that has been designated
          by the U.S. Government as a "terrorist supporting" country; and that
          You are not listed on any U.S. Government list of prohibited or
          restricted parties.
        </p>
        <h2 className="legal-title">12. CONTACT INFORMATION</h2>
        <p className="legal-body">
          For general inquiries, complaints, questions or claims concerning the
          licensed Application, please contact:
        </p>
        <p className="legal-body">
          Wandry
          <br />
          5728 Major Blvd. Suite 200
          <br />
          5728 Major Blvd. Suite 200
          <br />
          Orlando, FL 32819
          <br />
          United States
          <br />
          contact@wandry.app
        </p>
        <h2 className="legal-title">13. TERMINATION</h2>
        <p className="legal-body">
          The license is valid until terminated by Guest Experience Maximization
          LLC or by You. Your rights under this license will terminate
          automatically and without notice from Guest Experience Maximization
          LLC if You fail to adhere to any term(s) of this license. Upon License
          termination, You shall stop all use of the Application, and destroy
          all copies, full or partial, of the Application.
        </p>
        <h2 className="legal-title">
          14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
        </h2>
        <p className="legal-body">
          Guest Experience Maximization LLC represents and warrants that Guest
          Experience Maximization LLC will comply with applicable third-party
          terms of agreement when using licensed Application.
        </p>
        <p className="legal-body">
          In Accordance with Section 9 of the "Instructions for Minimum Terms of
          Developer's End-User License Agreement," Apple and Apple's
          subsidiaries shall be third-party beneficiaries of this End User
          License Agreement and - upon Your acceptance of the terms and
          conditions of this license agreement, Apple will have the right (and
          will be deemed to have accepted the right) to enforce this End User
          License Agreement against You as a third-party beneficiary thereof.
        </p>
        <h2 className="legal-title">15. INTELLECTUAL PROPERTY RIGHTS</h2>
        <p className="legal-body">
          Guest Experience Maximization LLC and the End-User acknowledge that,
          in the event of any third-party claim that the licensed Application or
          the End-User's possession and use of that licensed Application
          infringes on the third party's intellectual property rights, Guest
          Experience Maximization LLC, and not Apple, will be solely responsible
          for the investigation, defense, settlement and discharge or any such
          intellectual property infringement claims.
        </p>
        <h2 className="legal-title">16. APPLICABLE LAW</h2>
        <p className="legal-body">
          This license agreement is governed by the laws of the State of Florida
          excluding its conflicts of law rules.
        </p>
        <h2 className="legal-title">17. MISCELLANEOUS</h2>
        <p className="legal-body">
          17.1 If any of the terms of this agreement should be or become
          invalid, the validity of the remaining provisions shall not be
          affected. Invalid terms will be replaced by valid ones formulated in a
          way that will achieve the primary purpose.
        </p>
        <p className="legal-body">
          17.2 Collateral agreements, changes and amendments are only valid if
          laid down in writing. The preceding clause can only be waived in
          writing.
        </p>
      </div>
    </div>
  );
}

export default IOSTermsAndConditions;
